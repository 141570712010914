//登录

import React, {Component} from 'react';
import {withRouter} from 'react-router-dom'
import style from '../../../utils/config.scss'
import Container from '../../../component/container'
import MyInput from '../../../component/myInput'
import Icon from '../../../utils/icon'
import Http from "../../../services/http";
import PublicUtils from '../../../utils/publicUtils'
import Image from '../../../component/image'
import Header from '../../../component/header'
import global from '../../../global'
import model from "../../../services/model";
import FindPasswordAccoun from "../../findPassword-account";

import sha1 from "../../../services/sha1";
import {JSEncrypt} from 'jsencrypt'

class Theme1 extends Component {

    constructor(props) {



        super(props);
        this.state = {
            type: 1,
            form: {
                username: "",
                password: "",
                code: ""
            },
            tips: "",
            enableLoginBtn: false,
            showProgress: false,
            savePwd: true,
            verifyImg: null,
            PHPSESSID: null,
            is_verify: null
        };
        this.nextPage = PublicUtils.getState(this.props.location.pathname).nextPage;
        this.tips = PublicUtils.getState(this.props.location.pathname).tips;
        // alert(JSON.stringify(data));
    }

    componentDidMount() {
        this._fetchVerify();
    }

    componentWillUnmount() {
    }

    /**
     * 获取图形验证码
     * @private
     */
    _fetchVerify = () => {
        let parm = {}
        Http.request('verify', parm, (response) => {
            console.log(decodeURI(response.data.verify));
            if (response.code == 200) {
                this.PHPSESSID = response.data.PHPSESSID;
                this.setState({
                    is_verify: response.data.is_verify,
                    // PHPSESSID: response.data.PHPSESSID,
                    verifyImg: decodeURI(response.data.verify),
                    tn_code: decodeURI(response.data.tn_code),
                });
                const option = {
                    url: decodeURI(response.data.tn_code),
                    onBlockend: this._onLogin,
                    onRefresh: this._fetchVerify
                }
                // 初始化图形验证
                window.tncode.init(option);
            }
        })
    }

    /**
     * 表单验证
     * */
    _checkForm = (data) => {
        this.setState(Object.assign(this.state.form, data), () => {
            // if (!model.inputRule.username.rule.test(this.state.form.username)) {
            //     this.setState({enableLoginBtn: false, tips: model.inputRule.username.ruleMsg});
            //     return;
            // }
            // if (!model.inputRule.password.rule.test(this.state.form.password)) {
            //     this.setState({enableLoginBtn: false, tips: model.inputRule.password.ruleMsg});
            //     return;
            // }
            // if (this.state.form.username.length < 2 || this.state.form.username.length > 8) {
            //     this.setState({enableLoginBtn: false, tips: "请输入2~8位数字或字母的用户名"});
            //     return;
            // }
            // if (this.state.form.password.length < 6 || this.state.form.password.length > 12) {
            //     this.setState({enableLoginBtn: false, tips: "请输入6~12位数字或字母的密码"});
            //     return;
            // }
            this.setState({enableLoginBtn: true, tips: ""});
        });
    };
    /**
     * 登录
     * */
    _onLogin = () => {
        this.setState({showProgress: true});
        let timestamp=new Date().getTime();
        let param = JSON.stringify({user_account: this.state.form.username, login_password: this.state.form.password,timestamp:timestamp});
        // param = rsa_encode(param);
        // param = encodeURIComponent(param)
        var encrypt = new JSEncrypt();
        encrypt.setPublicKey(model.public_key);
        param = encrypt.encrypt(param); //password为需要加密的字段
        // param = encodeURIComponent(param);
        let parm = {
            device: global.DEVICE,
            // user_account: this.state.form.username,
            // login_password: this.state.form.password,
            signature: sha1([this.state.form.username, this.state.form.password, timestamp,'', global.DEVICE].sort().toString()),
            param: param
        }
        if (this.state.is_verify) {
            parm.PHPSESSID = this.PHPSESSID;
            // parm.code = this.state.form.code;
            parm.code = Math.round(window.tncode._mark_offset);
            parm.signature = sha1([this.state.form.username, this.state.form.password, timestamp, Math.round(window.tncode._mark_offset),global.DEVICE].sort().toString())
        }
        Http.request('login', parm, (response) => {
            this.setState({showProgress: false});
            if (this.state.is_verify) {
                if (response.code == 200) {
                    window.tncode._send_result_success(response);
                } else {
                    window.tncode._send_result_failure(response);
                }
            }
            if (response.data.status === 1) {
                if (response.data.user.lastmsg) {
                    PublicUtils.showToast(response.data.user.lastmsg.is_content, {duration: 10000});
                }
                if (response.data.token) {
                    response.data.isSavePwd = this.state.savePwd;
                    if (this.isSavePwd) {
                        response.data.userName = this.state.form.username;
                        response.data.passWord = this.state.form.password;
                    }
                    PublicUtils.saveUserInfo(response.data);
                }
                if (this.nextPage) {
                    PublicUtils.replaceNavigate(this.nextPage);
                } else {
                    PublicUtils.goBack(null)
                }
            } else {
                // 重新加载验证码
                this._fetchVerify();
                PublicUtils.showToast(response.data.message);
            }
        });
    };
    /**
     * 加载状态
     * */
    _showProgress = () => {
        // return <ActivityIndicator size="small" color={'#fff'}
        //                           style={{marginLeft: 5, position: "absolute"}}/>;
    };

    _fetchTncode = () => {
        if (!this.state.form.username) {
            PublicUtils.showToast("请输入用户名");
            return;
        }
        if (!this.state.form.password) {
            PublicUtils.showToast("请输入密码");
            return;
        }
        window.tncode.show();
        // window.tncode._img.src = decodeURI(this.state.tn_code);
        // window.tncode.onsuccess=()=>{
        //     alert(11);
        // }
        // Http.request('tnCode', {}, (response) => {
        //     console.log(response);
        //     window.tncode._img.src = response.data.verify;
        // });
    }

    render() {
        const styles = {
            container: {
                display: "flex",
                flexDirection: "column",
                flex: 1,
                paddingLeft: 20,
                paddingRight: 20,
                backgroundColor: "#fff"
            },
            headerContainer: {
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                height: 70,
                // backgroundColor: "#000",
                zIndex: 1
            },
            welcome: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                paddingTop: 20,
                paddingBottom: 20,
            },
            welcomeTips: {
                fontSize: 24
            },
            body: {
                marginTop: 10,
                display: "flex",
                flexDirection: "column",
            },
            item: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                // borderBottomColor: "#f5f5f5",
                // borderBottomWidth: 1,
                borderBottom: "1px solid #f5f5f5",
                height: 50
            },
            itemLeft: {
                display: "flex",
                flexDirection: "column",
                width: 40,
                alignItems: "center",
                justifyContent: "center"
            },
            itemRight: {
                display: "flex",
                flexDirection: "row",
                flex: 1
            },
            textIpt: {
                textAlign: "left",
                fontSize: 15,
                height: "auto"
            },
            yzmBtn: {
                // borderLeftColor: "#f5f5f5",
                // borderLeftWidth: 1,
                borderLeft: "1px solid #f5f5f5",
                padding: 10
            },
            btnContainer: {
                display: "flex",
                marginTop: 10,
                flexDirection: "row",
                alignItems: 'center',
                justifyContent: 'center',
            },
            btnBox: {
                display: "flex",
                flex: 1,
                backgroundColor: style['primaryColor' + model.SKINNAME],
                flexDirection: "row",
                alignItems: 'center',
                justifyContent: 'center',
                height: 40,
                borderRadius: 3
            },
            btnText: {
                color: "#fff",
                fontSize: 16
            },
            showTab: {
                display: "flex"
            },
            hideTab: {
                display: "none"
            },
            bottomBar: {
                marginTop: 50,
            },
            bottomBarHeader: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            bottomBarHeaderLeft: {
                display: "flex",
                flexDirection: "column",
                flex: 1,
                height: 1,
                // borderBottomColor: "#f5f5f5",
                // borderBottomWidth: 1
                borderBottom: "1px solid #f5f5f5"
            },
            bottomBarHeaderText: {
                paddingLeft: 10,
                paddingRight: 10,
                color: "#999"
            },
            bottomBarHeaderRight: {
                display: "flex",
                flexDirection: "column",
                flex: 1,
                height: 1,
                // borderBottomColor: "#f5f5f5",
                // borderBottomWidth: 1
                borderBottom: "1px solid #f5f5f5"
            },
            bottomBarBody: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            bottomBarBodyIcon: {
                display: "flex",
                width: 50,
                height: 50,
                borderRadius: 50,
                marginTop: 20,
                marginLeft: 20,
                marginRight: 20,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            color1: {
                backgroundColor: "#bf7c28",
            },
            color2: {
                backgroundColor: "#c66457",
            },
            color3: {
                backgroundColor: "#3eab42",
            },
            regTip: {
                marginTop: 10,
                paddingLeft: 10
            },
            regTipText: {
                color: "#ff4222"
            },
            disabledBtnText: {},
            disabledBtn: {
                opacity: 0.6
            },
            switchBox: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                fontSize: 14
            },
            bottomBtnBox: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 10
            },
            bottomBtnItemBox: {
                marginTop: 20
            },
            bottomBtnItemText: {
                fontSize: 14
            },
            loginTips: {
                display: "flex",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                height: 30,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center"
            },
            loginTipsText: {
                color: "#fff",
                marginLeft: 5
            },
            linkContainer: {
                display: "flex",
                marginTop: 10,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
            }
        };

        let logbg;
        switch (global.ID) {
            case 'x9':
                logbg =  <Image style={{height: 260, width: "100%"}} resizeMode='cover'
                                uri={'https://appnew.hdyb33.com/code/x9/yxtb/login-bg.jpg'}/>
                break;
            case 'w7':
                logbg =  <Image style={{height: 260, width: "100%"}} resizeMode='cover'
                                uri={'https://newpic.hdyb33.com/code/w7/tb/beij.png'}/>
                break;
            case 'ff':
                logbg =  <Image style={{height: 260, width: "100%"}} resizeMode='cover'
                                uri={'https://newpic.hdyb33.com/ff/login-bg.png'}/>
                break;
            default:
                logbg =  <Image style={{height: 260, width: "100%"}} resizeMode='cover'
                                uri={require('../../../assets/images/login-bg.jpg')}/>
                break;
        }

        return (
            <Container showHeader={false} tabIndex={1} title={'登录'} style={{background: "#fff"}}>
                <div style={styles.headerContainer}>
                    <Header fontColor={"#fff"} backgroundColor={'transparent'} leftButtonIconColor={"#fff"}
                            titleColor={"#fff"}></Header>
                </div>
                {logbg}
                {this.tips &&
                <div style={styles.loginTips}>
                    <Icon icon={'tips'} color={'#fff'} size={15}/>
                    <div style={styles.loginTipsText}>{this.tips}</div>
                </div>
                }
                <div style={styles.container}>
                    <div style={{...styles.body, ...this.state.type === 1 ? styles.showTab : styles.hideTab}}>
                        <div style={styles.item}>
                            <div style={styles.itemLeft}>
                                <Icon icon="user" size={20} color={style['primaryColor' + model.SKINNAME]}/>
                            </div>
                            <div style={styles.itemRight}>
                                <MyInput placeholder="请输入账号"
                                         style={styles.textIpt}
                                         onChangeText={(text) => {
                                             this._checkForm({username: text})
                                         }}/>
                            </div>
                        </div>
                        <div style={styles.item}>
                            <div style={styles.itemLeft}>
                                <Icon icon={'new-password'} size={20} color={style['primaryColor' + model.SKINNAME]}/>
                            </div>
                            <div style={styles.itemRight}>
                                <MyInput type={'password'} placeholder="请输入密码" value={this.state.form.password}
                                         secureTextEntry={true}
                                         style={styles.textIpt}
                                         onChangeText={(text) => {
                                             this._checkForm({password: text})
                                         }}/>
                            </div>
                        </div>
                        {/*{*/}
                        {/*    this.state.is_verify ?*/}
                        {/*        <div style={styles.item}>*/}
                        {/*            <div style={styles.itemLeft}>*/}
                        {/*                <Icon icon={'new-question'} size={20}*/}
                        {/*                      color={style['primaryColor' + model.SKINNAME]}/>*/}
                        {/*            </div>*/}
                        {/*            <div style={styles.itemRight}>*/}
                        {/*                <MyInput type={'password'} placeholder="请输入图形验证码"*/}
                        {/*                         value={this.state.form.code}*/}
                        {/*                         secureTextEntry={true}*/}
                        {/*                         style={styles.textIpt}*/}
                        {/*                         textContentType={'password'}*/}
                        {/*                         onChangeText={(text) => {*/}
                        {/*                             this._checkForm({code: text})*/}
                        {/*                         }}/>*/}
                        {/*                <img onClick={() => {*/}
                        {/*                    this._fetchVerify();*/}
                        {/*                }} src={this.state.verifyImg} style={{width: 100, height: 50}}/>*/}
                        {/*            </div>*/}
                        {/*        </div> : null*/}
                        {/*}*/}

                        <div style={styles.linkContainer}>
                            {
                                global.ID !== "ca" ?
                                    <div onClick={() => {

                                        if (global.ID === "aw") {
                                            PublicUtils.linking(model.serviceUrl, "_blank");
                                        } else {
                                            PublicUtils.navigate('FindPasswordAccoun');
                                        }
                                    }}>
                                        <div style={styles.bottomBtnItemText}>忘记密码？</div>
                                    </div>
                                    : null
                            }

                            <div onClick={() => {

                                if(global.ID == 'b3' || global.ID == 'b5'){

                                    let xlObj = JSON.parse(localStorage.getItem('line'))


                                    PublicUtils.linking(xlObj.url+global.ID+'/service',"_blank");
                                    return
                                }

                                PublicUtils.linking(model.serviceUrl, "_blank");
                            }} style={styles.switchBox}>
                                <div>联系客服</div>
                                {/*<SwitchBtn onTintColor='@onTintColor' tintColor='#ddd'*/}
                                {/*           thumbTintColor={'@thumbTintColor'}*/}
                                {/*           value={this.state.savePwd}*/}
                                {/*           onValueChange={(value) => {*/}
                                {/*               this.setState({*/}
                                {/*                   savePwd: value*/}
                                {/*               })*/}
                                {/*           }}/>*/}
                            </div>
                        </div>
                    </div>

                    <div style={styles.regTip}>
                        <div style={styles.regTipText}>{this.state.tips}</div>
                    </div>



                    <div style={styles.btnContainer}>


                        <div
                            disabled={!this.state.enableLoginBtn || this.state.showProgress}
                            style={{...styles.btnBox, ...!this.state.enableLoginBtn ? styles.disabledBtn : null}}
                            onClick={() => {
                                // this._onLogin();
                                if (this.state.is_verify) {
                                    this._fetchTncode();
                                } else {
                                    this._onLogin();
                                }
                            }}>
                            {this.state.showProgress ? this._showProgress() : <div
                                style={{...styles.btnText, ...!this.state.enableLoginBtn ? styles.disabledBtnText : null}}>立即登录</div>}
                        </div>
                    </div>
                    {/*<View style={styles.switchBox}>*/}
                    {/*    <MyText style={{marginRight: 10}}>记住密码</MyText>*/}
                    {/*    <SwitchBtn onTintColor='@onTintColor' tintColor='#ddd' thumbTintColor={'@thumbTintColor'}*/}
                    {/*               value={this.state.savePwd}*/}
                    {/*               onValueChange={(value) => {*/}
                    {/*                   this.setState({*/}
                    {/*                       savePwd: value*/}
                    {/*                   })*/}
                    {/*               }}/>*/}
                    {/*</View>*/}
                    <div style={styles.bottomBtnBox}>
                        <div onClick={() => {
                            PublicUtils.navigate('Register');
                        }}>
                            <div style={styles.bottomBtnItemBox}>
                                <div style={{...styles.bottomBtnItemText, ...{fontSize: 16}}}>没有账号？去注册</div>
                            </div>
                        </div>
                        {/*<div onClick={() => {*/}
                        {/*    // Linking.openURL(model.serviceUrl);*/}
                        {/*}}>*/}
                        {/*    <div style={styles.bottomBtnItemBox}>*/}
                        {/*        <div style={{...styles.bottomBtnItemText, ...{fontSize: 16}}}>联系客服</div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </Container>
        )
    }
}

export default withRouter(Theme1);
